<template>
  <div class="adress-manage-container">
    <div
      :style="explorerChromeSafari ? 'height: calc(100vh - 19rem) !important;overflow-y: auto;' : 'height: calc(100vh - 13rem) !important;overflow-y: auto;'"
      v-if="adressList.length == 0"
      class="col-center"
    >
      <img
        style="width:6.5rem;height:auto;margin-bottom:1.375rem;"
        src="../assets/icon/no-list.png"
      />
      <p style="font-size: 16px;font-family: lantingheiweight;font-weight: 500;color: #666666;line-height: 22px;margin-bottom: 5vh;">暂无地址</p>
    </div>
    <!-- 地址列表 -->
    <div
      v-else
      :style="explorerChromeSafari ? 'height: calc(100vh - 19rem) !important;overflow-y: auto;' : 'height: calc(100vh - 13rem) !important;overflow-y: auto;'"
    >
      <div
        class="adress-item col-center"
        :class="adressManageEdit ? 'adress-item-edit' : 'adress-item-noedit'"
        v-for="(item,index) in adressList"
        :key="index"
      >
        <div
          style="width: 100%;"
          class="row-between"
          @click="selectAdressId=item.id;"
        >
          <div
            style="margin-left: 14px;"
            class="col-start"
          >
            <div
              class="row-start"
              style="margin-bottom: 12px;"
            >
              <p>{{ item.realName }}</p>
              <p style="margin-left: 10px;">{{ item.phone }}</p>
              <div
                v-if="item.isDefault == 1"
                class="default-bg row-center"
              >默认</div>
            </div>
            <p>{{ item.province + '  ' + item.city + '  ' + item.district }}</p>
          </div>
          <div style="margin-right: 18px;font-size: 14px;">
            <a @click="editAdress(item.id)">编辑</a>
          </div>
        </div>

        <div
          v-if="adressManageEdit"
          class="adress-line"
        ></div>
        <div
          v-if="adressManageEdit"
          style="width: 100%;"
          class="row-between"
        >
          <div
            style="margin-left: 14px;"
            class="row-start"
            @click="selectAdressId=item.id;"
          >
            <img
              class="adress-edit-select"
              v-if="selectAdressId == item.id"
              :src="require('../assets/icon/donation/select.png')"
            />
            <img
              class="adress-edit-select"
              v-else
              :src="require('../assets/icon/donation/cancer.png')"
            />
            <p>设为默认</p>
          </div>
          <div style="margin-right: 18px;font-size: 14px;">
            <a @click="setDeleteId(item.id)">删除</a>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增地址按钮 -->
    <div
      class="row-center add-adress"
      @click="addAdress()"
    >新增收货地址</div>
    <el-dialog
      title="确定删除地址吗？"
      :visible.sync="deleteVisible"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="deleteVisible = false">取 消</el-button>
        <div
          @click="deleteAdress()"
          class="confirm-button row-center"
        >确定</div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from '../api/index-client'
import { mapGetters } from "vuex";
import Vue from 'vue';
import { Radio,Button,Dialog } from 'element-ui';
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Radio);
export default {
  data () {
    return {
      adressList: [],
      deleteVisible: false,
      explorerChromeSafari: false,
      selectAdressId: null,
      deleteAdressId: null,
    }
  },
  computed: {
    ...mapGetters(["adressManageEdit"]),
  },
  watch: {
    adressManageEdit () {
      if (!this.adressManageEdit) {
        this.editAddress()
      }
    },
  },
  mounted () {
    localStorage.removeItem('defaultAdress')
    this.$store.commit('SET_ADREESSMANAGEEDIT',false)
    // 获取地址列表
    this.getAdressList()
    this.checkUserAgent()
  },
  methods: {
    // 检查设备，适配
    checkUserAgent () {
      var explorer = navigator.userAgent

      if (/(micromessenger)/i.test(explorer)) {
        // 微信浏览器
        this.explorerChromeSafari = false
      } else if (/(MQQBrowser)/i.test(navigator.userAgent)) {
        // qq
        this.explorerChromeSafari = true
      } else if (/(?:Chrome|CriOS)/.test(explorer)) {
        // 谷歌
        this.explorerChromeSafari = true
      } else if (/(iPhone|iPad|iPod|iOS)/i.test(explorer)) {
        // 除上面3个浏览器外的ios设备
        this.explorerChromeSafari = true
      } else {
        // 其他设备
        this.explorerChromeSafari = false
      }
    },
    // 设置默认地址
    editAddress () {
      if (this.selectAdressId) {
        api
          .post('address/default/set',{ id: this.selectAdressId })
          .then(result => {
            if (result.data.success) {
              this.$toast.success('成功')
              this.getAdressList()
            } else {
              this.$toast({
                message: result.data.msg,
                icon: require('../assets/icon/toast-error.png'),
              });
            }
          })
      }
    },
    // 设置删除地址id
    setDeleteId (id) {
      this.deleteAdressId = id
      this.deleteVisible = true
    },
    // 删除地址
    deleteAdress () {
      api
        .post('address/delete',{ ids: [this.deleteAdressId] })
        .then(result => {
          if (result.data.success) {
            this.$toast.success('成功')
            this.deleteVisible = false
            this.getAdressList()
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取当前账户地址列表
    getAdressList () {
      api
        .get('address/list')
        .then(result => {
          if (result.data.success) {
            this.adressList = result.data.data
            this.$store.commit('HIDE_APPLOADING')
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 新增收货地址
    addAdress () {
      this.$router.replace('/addAdress')
    },
    editAdress (id) {
      this.$router.replace('/addAdress?id=' + id)
    }
  },
}
</script>
<style lang="less" scoped>
.adress-manage-container {
  margin-top: 3.875rem;
  height: calc(100vh - 3.875rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .adress-item-edit {
    min-height: 145px;
  }
  .adress-item-noedit {
    min-height: 116px;
  }
  .adress-item {
    margin-top: 14px;
    width: 90vw;
    margin-left: 5vw;
    background: #262728;
    border-radius: 8px;
    font-size: 13px;
    font-family: Noto Sans S Chinese;
    font-weight: 400;
    color: #cccccc;
    .default-bg {
      width: 60px;
      height: 29.5px;
      background-image: url('../assets/icon/my/default-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      margin-left: 10px;
      font-size: 13px;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #fefefe;
    }
    .adress-line {
      width: 94%;
      height: 1px;
      background: #5e5e5e;
      margin-top: 30px;
      margin-bottom: 10px;
    }
    .adress-edit-select {
      width: 20px;
      height: 20px;
      margin-right: 14px;
    }
  }
  .add-adress {
    width: 90vw;
    height: 46px;
    margin: 1.3125rem 5vw 1.4375rem 5vw;
    background-image: url('../assets/icon/confirm-button.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    letter-spacing: 2px;
    cursor: pointer;
    background-size: cover;
  }
}
</style>